<template>
  <section>
    <b-modal id="modal-user-college" hide-footer>
      <template #modal-header>
        <h4>
          {{
            data.id === null ? $t("user-college.new") : $t("user-college.edit")
          }}
        </h4>
      </template>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="new-user-info">
            <b-row class="justify-content-md-center">
              <b-form-group
                v-if="!data.id"
                class="col-md-12"
                label-for="education"
              >
                <ValidationProvider
                  :name="$t('user-college.user')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <label for="">{{ $t("user-college.user") }}</label>
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      class="py-0 mb-1 px-1"
                      type="button"
                      @click="$refs.modalAddUser.show()"
                    >
                      <span style="font-size: 11px">
                        {{ $t("user-college.new-user") }}
                      </span>
                    </b-button>
                  </div>
                  <v-select
                    v-model="data.user_id"
                    :placeholder="$t('app.min-search')"
                    :options="usersAll"
                    @search="(query) => getDebouncedUsers(query)"
                    label="name"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                    <template #option="{ first_lastname, name, email }">
                      <p style="margin: 0">
                        {{ `${name} ${first_lastname ? first_lastname : ""}` }}
                      </p>
                      <small>{{ email }}</small>
                    </template>
                    <template
                      #selected-option="{ first_lastname, name, email }"
                    >
                      <p style="margin: 0">
                        {{ `${name} ${first_lastname ? first_lastname : ""}` }}
                      </p>
                    </template>

                    <template #no-options>
                      {{ $t("app.not-found") }}...
                    </template>
                  </v-select>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-12"
                :label="$t('user-college.roles')"
                label-for="education"
              >
                <ValidationProvider
                  :name="$t('user-college.roles')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    multiple
                    v-model="data.roles"
                    :placeholder="$t('user-college.roles')"
                    :options="wrappedRoles"
                    label="name"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                    <template #no-options="{}">
                      {{ $t("app.not-found") }}...
                    </template>
                  </v-select>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <hr />
            <b-button
              :disabled="userCollegeLoading"
              variant="success"
              class="float-right"
              type="submit"
            >
              <span v-if="userCollegeLoading">
                <b-spinner small type="grow"></b-spinner>
                {{ $t("app.loading") }}...
              </span>
              <span v-else>
                {{ $t("app.save") }}
              </span>
            </b-button>
            <b-button
              @click="$bvModal.hide('modal-user-college')"
              class="float-right mr-1"
              variant="outline-danger"
              >{{ $t("app.cancel") }}</b-button
            >
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <modal-add-user ref="modalAddUser" @newUser="updateCurrentUser" />
  </section>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import ModalAddUser from "@/views/User/Components/ModalAddUser.vue";
import { ROLE_ADMIN_ID } from "@/constants/roles";

export default {
  name: "modalForm",
  components: {
    ModalAddUser,
  },
  mounted() {
    core.index();
  },
  watch: {
    "data.user_id": {
      handler() {
        if (this.data.user_id === null) {
          this.data.roles = "";
        }
      },
    },
  },
  data() {
    return {
      data: {
        id: null,
        user_id: null,
        roles: null,
      },
      users: {
        loading: false,
        debounce: null,
        debounceTime: 300,
      },
    };
  },
  methods: {
    ...mapActions({
      storeUserCollege: "storeUserCollege",
      fetchUsersAll: "fetchUsersAll",
      fetchRolesUserCollege: "fetchRolesUserCollege",
    }),
    updateCurrentUser(user) {
      this.data.user_id = user;
    },
    async getDebouncedUsers(search) {
      this.users.loading = true;
      clearTimeout(this.users.debounce);
      this.users.debounce = await setTimeout(async () => {
        if (search.length >= 2) {
          this.users.loading = true;
          try {
            let paramsSearch = {
              search,
            };
            await this.fetchUsersAll(paramsSearch);
          } catch (error) {
            return errror;
          } finally {
            this.users.loading = false;
          }
        }
      }, this.users.debounceTime);
    },
    async show(item) {
      this.reserForm();
      if (item) {
        await this.fetchRolesUserCollege(item.user.id);
        this.data = {
          id: item.id,
          user_id: item,
          roles: this.getUserRolesCollage,
        };
      }
      this.$bvModal.show("modal-user-college");
    },
    async onSubmit() {
      console.log(this.data.user_id);
      let payload = {
        id: this.data.id,
        user_id: this.data.user_id.user
          ? this.data.user_id.user.id
          : this.data.user_id.id,
        roles: this.data.roles.map((item) => item.id),
      };
      const resp = await this.storeUserCollege(payload);
      this.$bvModal.hide("modal-user-college");
      this.$emit("refresh");
      if (resp.status === 201 || resp.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("app.success-create")
            : this.$t("app.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        user_id: null,
        roles: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      userCollegeLoading: "userCollegeLoading",
      usersAll: "usersAll",
      roleCollege: "roleCollege",
      collageAuthUser: "collageAuthUser",
      getUserRolesCollage: "getUserRolesCollage",
    }),
    wrappedRoles() {
      return this.roleCollege.filter((rol) => rol.id !== ROLE_ADMIN_ID);
    },
  },
};
</script>
