var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("sidebar.user-college")))])]},proxy:true},{key:"headerAction",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.modalUserCollege.show()}}},[_vm._v(_vm._s(_vm.$t("app.new")))]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.show('bv-modal-example')}}},[_vm._v(_vm._s(_vm.$t("upload.upload")))])]},proxy:true},{key:"body",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"pr-1 mb-2",attrs:{"sm":"12","md":"1"}},[_c('b-form-select',{attrs:{"options":['5', '10', '20', '50', '100'],"size":"sm"},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filter.per_page),callback:function ($$v) {_vm.$set(_vm.filter, "per_page", $$v)},expression:"filter.per_page"}})],1),_c('b-col',{staticClass:"ml-auto mb-2",attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('v-select',{attrs:{"multiple":"","reduce":(role) => role.id,"placeholder":_vm.$t('user-college.roles'),"options":_vm.wrappedRoles,"label":"name"},on:{"input":function($event){return _vm.getData()}},scopedSlots:_vm._u([{key:"no-options",fn:function({}){return [_vm._v(" "+_vm._s(_vm.$t("app.not-found"))+"... ")]}}]),model:{value:(_vm.filter.roles),callback:function ($$v) {_vm.$set(_vm.filter, "roles", $$v)},expression:"filter.roles"}})],1),_c('b-col',{staticClass:"ml-auto mb-2",attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-input',{staticStyle:{"height":"35px"},attrs:{"id":"filter-input","disabled":!Array.isArray(_vm.getUserCollege.data) ||
                  (_vm.getUserCollege.data.length === 0 &&
                    _vm.userCollegeLoading === false),"type":"search","placeholder":_vm.$t('app.search')},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('b-col',{staticClass:"table-responsive",attrs:{"md":"12"}},[_c('b-table',{staticClass:"table mb-0 table-borderless",attrs:{"items":_vm.getUserCollege.data,"fields":_vm.columns,"per-page":_vm.filter.per_page,"filter":_vm.filter,"busy":!Array.isArray(_vm.getUserCollege.data) ||
                  _vm.getUserCollege.data.length === 0
                    ? true
                    : false || _vm.userCollegeLoading == true},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.userCollegeLoading)?_c('div',[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v(_vm._s(_vm.$t("app.loading"))+"...")])],1):_c('span',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t("app.not-found")))])])]},proxy:true},{key:"cell(roles)",fn:function(data){return _vm._l((data.item.roles),function(item){return _c('p',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])})}},{key:"cell(action)",fn:function(data){return [_c('b-button',{class:data.item.user.has_pin_code ? 'mr-1' : 'mr-1 disabled',attrs:{"variant":data.item.user.has_pin_code
                        ? ' iq-bg-success rounded'
                        : ' iq-bg-disabled-button rounded',"size":"sm"},on:{"click":function($event){return _vm.deletePin(data.item.user.id_user)}}},[(_vm.getLoadingRoles)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_c('i',{staticClass:"ri-lock-fill ml-1"})],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":" iq-bg-warning rounded","size":"sm"},on:{"click":function($event){return _vm.$refs.modalUserCollege.show(data.item)}}},[(_vm.getLoadingRoles)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_c('i',{staticClass:"ri-ball-pen-fill ml-1"})],1),_c('b-button',{attrs:{"disabled":_vm.getLoadingRoles,"variant":" iq-bg-danger rounded","size":"sm"},on:{"click":function($event){return _vm.deleteItem(data.item.user.id)}}},[_c('i',{staticClass:"ri-delete-bin-line ml-1"})])]}}])}),_c('b-pagination',{staticClass:"mt-3 float-right",attrs:{"total-rows":_vm.getUserCollege.total,"per-page":_vm.getUserCollege.per_page,"first-number":"","pills":"","size":"sm"},on:{"change":_vm.getData},model:{value:(_vm.filter.page),callback:function ($$v) {_vm.$set(_vm.filter, "page", $$v)},expression:"filter.page"}}),_c('p',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t("app.registers"))+" "+_vm._s(_vm.getUserCollege.to)+" "+_vm._s(_vm.$t("app.of"))+" "+_vm._s(_vm.getUserCollege.total)+". Total "+_vm._s(_vm.getUserCollege.total)+" ")])],1)],1)]},proxy:true}])})],1)],1),_c('modal-form',{ref:"modalUserCollege",on:{"refresh":function($event){return _vm.getData()}}}),_c('b-modal',{attrs:{"id":"bv-modal-example","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.$t("upload.upload")))])]},proxy:true}])},[_c('MassiveLoadTeacher',{on:{"refresh":function($event){return _vm.getData()},"close":function($event){return _vm.$bvModal.hide('bv-modal-example')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }