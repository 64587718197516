<template>
  <b-modal id="modal-add-user" hide-footer size="lg">
    <template #modal-header>
      <h4>
        {{ $t("user-college.new-user") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row align-h="center">
          <b-col md="12">
            <b-row align-v="center">
              <b-form-group
                class="col-md-6"
                :label="$t('profile.rut')"
                label-for="rut"
              >
                <ValidationProvider
                  :name="$t('profile.rut')"
                  rules="required|min:9"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="user.rut"
                    type="text"
                    :placeholder="$t('profile.rut')"
                    v-mask="['##.###.###-X', '#.###.###-X']"
                  ></b-form-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="$t('profile.name')"
                label-for="name"
              >
                <ValidationProvider
                  :name="$t('profile.name')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="user.name"
                    type="text"
                    :placeholder="$t('profile.name')"
                  ></b-form-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="$t('profile.first_lastname')"
                label-for="fname"
              >
                <ValidationProvider
                  :name="$t('profile.first_lastname')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="user.first_lastname"
                    type="text"
                    :placeholder="$t('profile.first_lastname')"
                  ></b-form-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="$t('profile.second_lastname')"
                label-for="lname"
              >
                <ValidationProvider
                  :name="$t('profile.second_lastname')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="user.second_lastname"
                    type="text"
                    :placeholder="$t('profile.second_lastname')"
                  ></b-form-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="$t('profile.email')"
                label-for="email"
              >
                <ValidationProvider
                  vid="email"
                  :name="$t('profile.email')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    class="form-control mb-0"
                    id="email"
                    name="email"
                    :placeholder="$t('profile.email')"
                    v-model="user.email"
                  />
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="$t('profile.phone')"
                label-for="phone"
              >
                <ValidationProvider
                  vid="phone"
                  :name="$t('profile.phone')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <vue-tel-input
                    :dropdownOptions="{
                      showFlags: true,
                      width: '200px',
                      tabindex: 1,
                      showDialCodeInSelection: false,
                    }"
                    mode="international"
                    :inputOptions="{
                      placeholder: $t('colleges.phone'),
                      maxlength: 15,
                      showDialCode: false,
                    }"
                    :defaultCountry="56"
                    v-model="user.phone"
                  ></vue-tel-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="$t('profile.password')"
                label-for="password"
              >
                <ValidationProvider
                  :name="$t('profile.password')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="user.password"
                    type="password"
                    :placeholder="$t('profile.password')"
                  ></b-form-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-danger"
            @click="closeModal"
            class="float-right mr-1"
          >
            {{ $t("app.cancel") }}
          </b-button>
          <b-button
            type="submit"
            :disabled="usersLoading"
            variant="success"
            class="mr-2"
          >
            <span v-if="usersLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("profile.loading") }}...
            </span>
            <span v-else> {{ $t("app.save") }} </span>
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {
        rut: "",
        name: "",
        first_lastname: "",
        second_lastname: "",
        email: "",
        password: "",
        phone: "",
      },
    };
  },
  methods: {
    ...mapActions({
      storeUser: "createUser",
    }),
    async show() {
      this.reserForm();
      this.$bvModal.show("modal-add-user");
    },
    closeModal() {
      this.$bvModal.hide("modal-add-user");
    },
    async onSubmit() {
      const resp = await this.storeUser(this.user);
      if (resp?.status === 201) {
        core.showSnackbar(
          "success",
          this.$t("user-college.created-successfully")
        );
        this.$emit("newUser", resp.data);
        this.closeModal();
      }
    },
    reserForm() {
      this.user = {
        rut: "",
        name: "",
        first_lastname: "",
        second_lastname: "",
        email: "",
        password: "",
        phone: "",
        locale: "",
      };
    },
  },
  computed: {
    ...mapGetters({
      usersLoading: "usersLoading",
    }),
  },
};
</script>

<style></style>
